import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-531d1062"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "home-box"
};
const _hoisted_2 = {
  class: "tab-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  const _component_Error = _resolveComponent("Error");
  return $data.data ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, (item, idx) => {
    return _openBlock(), _createBlock(_component_van_image, {
      key: idx,
      width: "100%",
      fit: "contain",
      src: item
    }, null, 8, ["src"]);
  }), 128))])])) : (_openBlock(), _createBlock(_component_Error, {
    key: 1,
    message: "这里没有更多的信息"
  }));
}